import axios from '../libs/axios';

const tool = {
  // 商户
  storeList: '/api/trd/store/list',
  storeListAll: '/api/trd/store/all',
  storeSave: '/api/trd/store/save',
  storeInfo: '/api/trd/store/info/',
  storeStatus: '/api/trd/store/status',

  // 门店
  shopList: '/api/trd/shop/list',
  shopSave: '/api/trd/shop/save',
  shopUpdate: '/api/trd/shop/update',
  shopInfo: '/api/trd/shop/info/',
  shopStatus: '/api/trd/shop/status',
};

// 商户列表
export const storeList = (data) => {
  return axios.get(tool.storeList, data);
};

// 下拉菜单的select
export const storeListAll = (data) => {
  return axios.get(tool.storeListAll, data);
};


// 商户信息
export const storeInfo = (storeId) => {
  return axios.get(tool.storeInfo + storeId);
};

// 商户保存/修改
export const storeSave = (data) => {
  return axios.post(tool.storeSave, data);
};

// 启用/禁用商户
export const storeStatus = (data) => {
  return axios.get(tool.storeStatus, data);
};

// 门店列表
export const shopList = (data) => {
  return axios.get(tool.shopList, data);
};

// 门店信息
export const shopInfo = (shopId) => {
  return axios.get(tool.shopInfo + shopId);
};

// 门店保存
export const shopSave = (data) => {
  return axios.post(tool.shopSave, data);
};

// 门店修改
export const shopUpdate = (data) => {
  return axios.post(tool.shopUpdate, data);
};

// 启用/禁用门店
export const shopStatus = (data) => {
  return axios.get(tool.shopStatus, data);
};
