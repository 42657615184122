<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="info" v-if="paramsId">
        <div>
          <span class="mr-20">商户编号：{{form.storeId}}</span>
          <span class="mr-20">商户状态：{{form.statusName}}</span>
        </div>
      </div>
      <div slot="content">
        <a-card title="商户信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="商户名称" prop="storeName">
                  <a-input v-model="form.storeName" :maxLength="50" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="联系人" prop="contact">
                  <a-input v-model="form.contact" :maxLength="50" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="手机号" prop="mobile">
                  <a-input v-model="form.mobile" :maxLength="50" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="邮箱" prop="mail">
                  <a-input v-model="form.mail" :maxLength="50" allowClear :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="10">
                <a-form-model-item label="地址" prop="address">
                  <a-input v-model="form.address" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" allowClear :maxLength="150" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="备注">
                  <a-input v-model="form.remark" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" allowClear :maxLength="150" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { storeInfo, storeSave } from '@/api/tool';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'storeForm',
  mixins: [formMixin],
  components: {},
  props: {},
  data() {
    return {
      form: {
        storeName: '',
        contact: '',
        mobile: '',
        mail: '',
        address: '',
        remark: '',
      },
      rules: {
        storeName: [
          {
            required: true,
            message: '请输入商户名称',
            trigger: 'blur',
          },
        ],
        contact: [
          {
            required: true,
            message: '请输入联系人'
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入手机号'
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        email: [
          {
            trigger: 'blur',
            type: 'email',
            message: '请输入正确的邮箱'
          }
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取信息
    getData(id) {
      storeInfo(id).then((data) => {
        this.form = data;
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = this.form;
          storeSave(params).then(() => {
            this.$message.success('操作成功');
            this.handleBack();
          })
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
